
import * as vercelRuntime$nUWwSWOSsT from '/vercel/path0/node_modules/.pnpm/@nuxt+image@1.8.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.24.3_webpack-sources@3.2.3/node_modules/@nuxt/image/dist/runtime/providers/vercel'
import * as cmsRuntime$IePxxf0EDG from '/vercel/path0/node_modules/.pnpm/@nuxt+image@1.8.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.24.3_webpack-sources@3.2.3/node_modules/@nuxt/image/dist/runtime/providers/bunny'
import * as shopRuntime$IePxxf0EDG from '/vercel/path0/node_modules/.pnpm/@nuxt+image@1.8.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.24.3_webpack-sources@3.2.3/node_modules/@nuxt/image/dist/runtime/providers/bunny'

export const imageOptions = {
  "screens": {
    "xs": 448,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536,
    "3xl": 1920
  },
  "presets": {},
  "provider": "vercel",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['vercel']: { provider: vercelRuntime$nUWwSWOSsT, defaults: {} },
  ['cms']: { provider: cmsRuntime$IePxxf0EDG, defaults: {"baseURL":"https://jp-rosselet-cms.b-cdn.net"} },
  ['shop']: { provider: shopRuntime$IePxxf0EDG, defaults: {"baseURL":"https://jp-rosselet-shop.b-cdn.net"} }
}
        